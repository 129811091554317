// Variables
$font-family-base: 'Roboto', 'Helvetica Neue', sans-serif !default;
$font-color-base: #464646 !default;
$font-size-base: 1rem !default;
$font-weight-base: 300 !default;

$heading-sizes: (
  1: (font-size: $font-size-base * 2.25, line-height: 1.2),
  2: (font-size: $font-size-base * 2, line-height: 1.25),
  3: (font-size: $font-size-base * 1.75, line-height: 1.3),
  4: (font-size: $font-size-base * 1.5, line-height: 1.35),
  5: (font-size: $font-size-base * 1.25, line-height: 1.4),
  6: (font-size: $font-size-base, line-height: 1.5)
);

// Color system
$background-color: #fbfbfb !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// fusv-disable
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// fusv-enable

$white:   #ffffff !default;
$blue:    #1ba2e8 !default;
$cyan:    #93e8e8 !default;
$yellow:  #e8c218 !default;
$green:   #41d33e !default;
$orange:  #ff8b00 !default;
$pink:    #f20a76 !default;
$red:     #e33e38 !default;
$purple:  #8e38e3 !default;
$brown:   #7a3d00 !default;
$black:   #000000 !default;

// scss-docs-start colors-map
$colors: (
  "white":      $white,
  "blue":       $blue,
  "cyan":       $cyan,
  "yellow":     $yellow,
  "green":      $green,
  "orange":     $orange,
  "pink":       $pink,
  "red":        $red,
  "purple":     $purple,
  "black":      $black,
  "gray":       $gray-500
) !default;
// scss-docs-end colors-map

// $primary:       #45cf63 !default;
$primary:       #0b2431 !default;
$secondary:     #f3f4ee !default;

$info:          $blue !default;
$success:       $primary !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-800 !default;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "info":       $info,
  "success":    $success,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "white":      $white
) !default;

// Padding system
$gap-0: 0px;
$gap-1: 8px;
$gap-2: 16px;
$gap-3: 24px;
$gap-4: 32px;
$gap-5: 48px;
$gap-6: 64px;
$gap-7: 72px;
$gap-8: 96px;
$gap-9: 128px;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
