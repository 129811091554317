@import "./styles/variables";
@import "./styles/typography";
@import "./styles/section";

@font-face {
  font-family: 'Extra Grotesque';
  src: url('../public/assets/fonts/extra-grotesque.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import "~bootstrap/scss/bootstrap";

@import "./styles/grafismos";
@import "./styles/divider";
