.grafismos {
  &--azul {
    z-index: 999;
    position: absolute;
    width: 16em;
    left: 80%;
    transform: translateY(-5em);
    opacity: .8;
  }

  &--verde {
    z-index: 999;
    position: absolute;
    width: 16em;
    right: 82%;
    transform: translateY(-4.5em);
    opacity: .5;
  }
}
