@import './variables';

.divider {
  &--horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: $primary;
    letter-spacing: 0.1em;
    width: 100%;
    padding: $gap-5;

    &::before,
    &::after {
      content: '';
      height: 1px;
      background-color: $primary;
      flex-grow: 1;
    }

    &::before {
      margin-right: 1rem;
    }

    &::after {
      margin-left: 1rem;
    }
  }

  &--vertical {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    &__text {
      color: $primary;
      font-weight: bold;
      writing-mode: horizontal-tb;
      transform: rotate(0deg);
    }

    &::before,
    &::after {
      content: '';
      width: 1px;
      height: 100px;
      background-color: $primary;
    }

    &::before {
      margin-bottom: 0.5rem;
    }

    &::after {
      margin-top: 0.5rem;
    }

    @media (max-width: 991px) {
      flex-direction: row;
    }
  }
}
