@import './variables';

.section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;

  &-lg {
    padding: 100px 0;
  }

  &-title {
    letter-spacing: 2px;
    margin-bottom: 0;
    padding-bottom: 1rem;
    line-height: 1;
    font-size: 1.75rem;
    font-weight: 700;
  }

  &-title-border {
    width: 50px;
    height: 2px;
    margin-right: auto;
    margin-left: auto;
    background-color: $orange;
  }

  &-subtitle {
    letter-spacing: .02em;
    color: $font-color-base;
  }
}
